<template>
	<span >
		{{content}}
		<MyTooltip v-show="tooltipProps.show"
			:title="tooltipProps.title"
			:display-mode="tooltipProps.displayMode">
			<a-icon :type="tooltipProps.icon"
				:class="cssClasses"/>
		</MyTooltip>
	</span>
</template>

<script>
import MyTooltip from "@components/common/MyTooltip.vue"
import {isStringEmpty} from "@utils/stringUtil"
export default {
	components : {
		MyTooltip
	} ,
	props : {
		content : {
			type: null,
			default : ''
		},
		error : {
			type : String,
			default : null
		} ,
		warning : {
			type : String,
			default : null
		}
	} ,
	computed : {
		cssClasses() {
			const css = []
			if (!isStringEmpty(this.error)) {
				css.push('text-error')
			} else if (!isStringEmpty(this.warning)) {
				css.push('text-warning')
			}
			return css;
		} ,
		tooltipProps() {
			const tooltipProps = {
				show : false,
				title : undefined,
				icon : "minus" ,
				displayMode : undefined
			}
			if (this.loading) {
				tooltipProps.show = true
				tooltipProps.title = this.$t("common.loading")
				tooltipProps.icon = "loading"
			} else if (!isStringEmpty(this.error)) {
				tooltipProps.show = true
				tooltipProps.title = this.error
				tooltipProps.icon = "close-circle"
				tooltipProps.displayMode = 'error'
			} else if (!isStringEmpty(this.warning)) {
				tooltipProps.show = true
				tooltipProps.title = this.warning
				tooltipProps.icon = "exclamation-circle"
				tooltipProps.displayMode = 'warning'
			}
			return tooltipProps
		}
	}
}
</script>
